import { Directive, OnDestroy, AfterViewInit, ElementRef, Renderer2, Input } from '@angular/core';
import { LocalizationService } from './services/localizationService';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { CultureService } from './services/cultureService';

@Directive({
  // tslint:disable-next-line:directive-selector
    selector: '[translateWithLinks]'
})
export class TranslateWithLinksDirective implements AfterViewInit, OnDestroy {
    @Input() translateWithLinks: string;
    private _listenClickFunc: () => void;
    private _onCultureChange: Subscription;
    constructor(private _element: ElementRef,
                private _localizationService: LocalizationService,
                private _router: Router,
                private _route: ActivatedRoute,
                private _cultureService: CultureService,
                private _renderer: Renderer2) {}

    ngAfterViewInit() {
        this.translate();
        this._onCultureChange = this._cultureService.onChange.subscribe(() => {
            this.translate();
        });

    }

    ngOnDestroy() {
        this.destroyElementHandlers();
        if (this._onCultureChange) {
            this._onCultureChange.unsubscribe();
            this._onCultureChange = undefined;
        }
    }

    private translate() {
        this.destroyElementHandlers();
        this._localizationService.get(this.translateWithLinks).subscribe((translateText: string) => {
            this._element.nativeElement.innerHTML = translateText;
            const navigationElements = Array.prototype.slice.call(this._element.nativeElement.querySelectorAll('a[routerLink]'));
            navigationElements.forEach((elem: any) => {
                this._listenClickFunc = this._renderer.listen(elem, 'click', (event) => {
                    event.preventDefault();

                    const navigationParams: NavigationExtras = {
                        relativeTo: this._route
                    };
                    this._router.navigate([elem.getAttribute('routerLink')], navigationParams);
                });
            });
        });
    }

    private destroyElementHandlers() {
        if (this._listenClickFunc) {
            this._listenClickFunc();
        }
    }
}
