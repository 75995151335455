import { APP_INITIALIZER, Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { CoreModule } from 'ngx-myia-core';
import { ReduxModule } from 'ngx-myia-redux';
import { TranslateModule } from '@ngx-translate/core';

import { FormatDatePipe } from './formatDatePipe';
import { TransPipe } from './transPipe';
import { CultureService } from './services/cultureService';
import { ILocalizationModuleConfig } from './localizationConfig';
import { TranslateLoaderWrapper } from './translateLoaderWrapper';
import { TranslateWithLinksDirective } from './translateWithLinksDirective';

export function appInitializerFactory(
    cultureService: CultureService
): () => Promise<any> {
    return () => {
        return new Promise<any>(resolve => {
            cultureService.initialize();
            resolve();
        });
    };
}

export function POLoaderFactory(injector: Injector) {
    return new TranslateLoaderWrapper(injector);
}

// @dynamic
@NgModule({
    imports: [
        CoreModule,
        ReduxModule,
        TranslateModule.forChild({})
    ],
    declarations: [FormatDatePipe, TranslateWithLinksDirective, TransPipe],
    exports: [FormatDatePipe, TranslateModule, TranslateWithLinksDirective, TransPipe],
})
export class LocalizationModule {
    static forRoot(
        localizationModuleConfig: ILocalizationModuleConfig
    ): ModuleWithProviders {
        return {
            ngModule: LocalizationModule,
            providers: [
                localizationModuleConfig.config,
                {
                    provide: APP_INITIALIZER,
                    useFactory: appInitializerFactory,
                    deps: [CultureService],
                    multi: true
                }
            ]
        };
    }
}
