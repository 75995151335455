import { Pipe, PipeTransform, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { TimerService } from 'ngx-myia-core';
import { CultureService } from './services/cultureService';
import { FormatDateService } from './services/formatDateService';
import { Subscription, Observable, of } from 'rxjs';

@Pipe({
  name: 'formatDate',
  pure: false // required to update the value when the promise is resolved
})
export class FormatDatePipe implements PipeTransform, OnDestroy {

  static updatingInterval: number = 15 * 1000; // 30s
  static formatDatePipeTimerKey = 'formatDatePipeTimerKey';
  static formatDatePipeUntilTimerKey = 'formatDatePipeUntilTimerKey';

  private _onChange: any;
  private _timerSubscription: Subscription;
  private _untilTimerSubscription: Subscription;

  constructor(private _formatDateService: FormatDateService, private _changeDetectorRef: ChangeDetectorRef, private _cultureService: CultureService, private _timerService: TimerService) {
    // subscribe to onChange event, in case the culture changes
    this._onChange = this._cultureService.onChange.subscribe(() => {
      this._changeDetectorRef.markForCheck();
    });
  }

  transform(value: Date | number | string, format?: string): Observable<string> {
    if (value === undefined || value === null) {
      return of(null);
    }

    if (format === 'LShort') {
      return of(this._formatDateService.getShortDateTime(value as string));
    }

    if (format === 'fromNow' && !this._timerSubscription) {
      const timer = this._timerService.getTimer(FormatDatePipe.formatDatePipeTimerKey, FormatDatePipe.updatingInterval);
      this._timerSubscription = timer.subscribe(() => {
        this._changeDetectorRef.markForCheck();
      });
    }
    if (format === 'until') {
      if (!this._untilTimerSubscription) {
        const timer = this._timerService.getTimer(FormatDatePipe.formatDatePipeUntilTimerKey, 1);
        this._untilTimerSubscription = timer.subscribe(() => {
          this._changeDetectorRef.markForCheck();
        });
      }
      return this._formatDateService.getTimeFromNow(value as Date);
    }
    if (format === 'duration') {
      return this._formatDateService.getDurationFromSeconds(value as number);
    }
    return of(this._formatDateService.getFormattedDate(value as Date, format));
  }

  ngOnDestroy() {
    if (this._onChange) {
      this._onChange.unsubscribe();
      this._onChange = undefined;
    }
    if (this._timerSubscription) {
      this._timerSubscription.unsubscribe();
      this._timerSubscription = undefined;
    }
    if (this._untilTimerSubscription) {
      this._untilTimerSubscription.unsubscribe();
      this._untilTimerSubscription = undefined;
    }
  }
}
