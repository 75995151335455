import { Injectable, EventEmitter, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Observer } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { setCulture } from '../redux/cultureActions';
import { ReduxStore } from 'ngx-myia-redux';
import { LOCALIZATION_CONFIGURATION } from '../localizationConfig';
import { ILocalizationConfigInterface } from '../ILocalizationConfig.interface';
import { cultureReducerKey, ICultureState } from '../redux/cultureReducers';
import { Logger } from 'ngx-myia-core';

@Injectable({providedIn: 'root'})
export class CultureService {

    public onChange: EventEmitter<string>;

    constructor(private _store: ReduxStore, private _translateService: TranslateService, @Inject(LOCALIZATION_CONFIGURATION) private _configuration: ILocalizationConfigInterface, private _logger: Logger) {
        this.onChange = new EventEmitter();
    }

    public get currentCulture() {
        return this._translateService.currentLang;
    }

    public initialize() {
        this.getStoredCulture().pipe(
            map((culture) => {
                if (!culture) {
                    culture = this.getDefaultLang();
                }
                this._translateService.setDefaultLang(culture); // this language will be used as a fallback when a translation isn't found in the current language
                return culture;
            })
        ).subscribe(culture => {
            this.use(culture);
        });
    }

    public use(culture: string): Observable<void> {
        // CultureService.putLanguageToStorage(culture);
        return this._translateService.use(culture).pipe(
            tap(() => {
                this._store.dispatch(setCulture(culture));
                this.onChange.emit(culture);
            })
        );
    }

    public getSupportedCultures(): Array<string> {
        return this._configuration.supportedCultures;
    }

    switchCulture(culture: string) {
        if (culture && culture !== this.currentCulture && this.getSupportedCultures().find(c => c === culture)) {
            this.use(culture);
        }
    }

    private getStoredCulture(): Observable<string> {
        return new Observable((observer: Observer<string>) => {
            const cultureState: ICultureState = this._store.getState(cultureReducerKey);
            if (cultureState) {
                let isRehydrating = cultureState._isRehydrating;
                if (isRehydrating) {
                    // wait for result
                    this._logger.log('Waiting for rehydrated culture ...');
                    const sub = this._store.subscribe((state: any) => {
                        const currentCultureState: ICultureState = state[cultureReducerKey];
                        isRehydrating = currentCultureState._isRehydrating;
                        if (!isRehydrating) {
                            sub(); // release store subscription
                            observer.next(currentCultureState.culture);
                            observer.complete();
                        }
                    }, [cultureReducerKey]);
                } else {
                    observer.next(cultureState.culture);
                    observer.complete();
                }
            } else {
                this._logger.info('stored culture not found.');
                observer.next(null);
                observer.complete();
            }
        }).pipe(
            map((userLang: string) => {
                if (!userLang) {
                    // use browser language
                    userLang = navigator.language ? navigator.language.split('-')[0] : null; // use navigator lang if available
                }
                // check supported cultures
                userLang = !this._configuration.supportedCultures || this._configuration.supportedCultures.indexOf(userLang) === -1 ? this.getDefaultLang() : userLang;
                return userLang;
            })
        );
    }

    private getDefaultLang(): string {
        return this._configuration && this._configuration.defaultCulture ? this._configuration.defaultCulture : 'en';
    }

}
