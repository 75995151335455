import { InjectionToken, Provider } from '@angular/core';

import { ILocalizationConfigInterface } from './ILocalizationConfig.interface';

export interface ILocalizationModuleConfig {
    config?: Provider;
}

export const LOCALIZATION_CONFIGURATION = new InjectionToken<
    ILocalizationConfigInterface
>('LOCALIZATION_CONFIGURATION');
